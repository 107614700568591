var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-card-title',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"8"}},[_c('CustomTextField',{attrs:{"clearable":"","id":"search","inputIcon":"mdi-magnify","inputPlaceholder":"Search...","inputValue":_vm.search},on:{"input":function($event){return _vm.updateLocalForm($event, 'search')},"click:clear":function($event){_vm.search = ''}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('div',{class:{ 'float-right': !_vm.$vuetify.breakpoint.xs }},[_c('CustomButton',{attrs:{"block":_vm.$vuetify.breakpoint.xs,"btnColour":"primary","btnLabel":"Add Client","tooltipMsg":"Add Client"},on:{"click":_vm.showCreateDialog}})],1)])],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"footer-props":_vm.footers,"headers":_vm.headers,"items":_vm.clients,"loading":_vm.retrieveClientOps.isLoading,"loading-text":"Loading...","no-data-text":"No data so far.","no-results-text":"No matches found.","options":_vm.options,"search":_vm.search,"server-items-length":_vm.retrieveClientOps.serverItemsLength,"show-select":""},on:{"update:options":function($event){_vm.options=$event},"toggle-select-all":_vm.toggleBulkActions,"update:items-per-page":function($event){return _vm.retrieveItems(_vm.options, _vm.search)},"update:page":function($event){return _vm.retrieveItems(_vm.options, _vm.search)},"update:sort-by":function($event){return _vm.retrieveItems(_vm.options, _vm.search)},"update:sort-desc":function($event){return _vm.retrieveItems(_vm.options, _vm.search)}},scopedSlots:_vm._u([{key:"item.datetime_created",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.toLocalDateTime(item.datetime_created)))])]}},{key:"item.datetime_disabled",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.isDisabled(item.datetime_disabled)
                            ? 'red--text'
                            : 'green--text'},[_c('strong',[_vm._v(" "+_vm._s(_vm.isDisabled(item.datetime_disabled) ? "Disabled" : "Enabled")+" ")])])]}},{key:"item.actions",fn:function(ref){
                            var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":"","tile":""},on:{"click":function($event){return _vm.viewItem(item)}}},[_c('v-icon',[_vm._v("mdi-file-find")])],1),_c('v-menu',{attrs:{"close-on-click":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","tile":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[(!_vm.isDisabled(item.datetime_disabled))?_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){return _vm.showDisableDialog(item)}}},[_c('v-list-item-icon',{staticClass:"avsb-table-action-icons"},[_c('v-icon',{attrs:{"dense":""}},[_vm._v(" mdi-lock ")])],1),_c('v-list-item-content',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"mb-0"},'div',attrs,false),on),[_c('span',[_vm._v("Disable")])])]}}],null,true)},[_c('span',[_vm._v("Disable Client")])])],1)],1):_vm._e(),(_vm.isDisabled(item.datetime_disabled))?_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){return _vm.showEnableDialog(item)}}},[_c('v-list-item-icon',{staticClass:"avsb-table-action-icons"},[_c('v-icon',{attrs:{"dense":""}},[_vm._v(" mdi-lock-open ")])],1),_c('v-list-item-content',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"mb-0"},'div',attrs,false),on),[_c('span',[_vm._v("Enable")])])]}}],null,true)},[_c('span',[_vm._v("Enable Client")])])],1)],1):_vm._e(),_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){return _vm.showRemoveDialog(item)}}},[_c('v-list-item-icon',{staticClass:"avsb-table-action-icons"},[_c('v-icon',{attrs:{"dense":""}},[_vm._v(" mdi-delete ")])],1),_c('v-list-item-content',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"mb-0"},'div',attrs,false),on),[_c('span',[_vm._v("Remove ")])])]}}],null,true)},[_c('span',[_vm._v("Remove Client")])])],1)],1)],1)],1)]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1),_c('DialogClientView'),_c('CustomActionConfirmationDialog',{attrs:{"dlgConfirmColour":"error","dlgConfirmMessage":"Disable","dlgHeader":"Disable Client","dlgLoading":_vm.disableOps.isLoading,"dlgShow":_vm.disableOps.dialog},on:{"closeDialog":_vm.hideDisableDialog,"actionCancel":_vm.hideDisableDialog,"actionConfirm":function($event){return _vm.disableItem(_vm.disablePayload)}},scopedSlots:_vm._u([{key:"question",fn:function(){return [_c('span',[_vm._v("Are you sure you want to disable the following item: "),_c('strong',[_vm._v(_vm._s(_vm.disablePayload.name))])])]},proxy:true}])}),_c('CustomActionConfirmationDialog',{attrs:{"dlgConfirmMessage":"Enable","dlgHeader":"Enable Client","dlgLoading":_vm.enableOps.isLoading,"dlgShow":_vm.enableOps.dialog},on:{"closeDialog":_vm.hideEnableDialog,"actionCancel":_vm.hideEnableDialog,"actionConfirm":function($event){return _vm.enableItem(_vm.enablePayload)}},scopedSlots:_vm._u([{key:"question",fn:function(){return [_c('span',[_vm._v("Are you sure you want to enable the following item: "),_c('strong',[_vm._v(_vm._s(_vm.enablePayload.name))])])]},proxy:true}])}),_c('CustomActionConfirmationDialog',{attrs:{"dlgConfirmColour":"error","dlgConfirmMessage":"Remove","dlgHeader":"Remove Client","dlgLoading":_vm.removeOps.isLoading,"dlgShow":_vm.removeOps.dialog},on:{"closeDialog":_vm.hideRemoveDialog,"actionCancel":_vm.hideRemoveDialog,"actionConfirm":function($event){return _vm.removeItem(_vm.removePayload)}},scopedSlots:_vm._u([{key:"question",fn:function(){return [_c('span',[_vm._v("Are you sure you want to remove the following item: "),_c('strong',[_vm._v(_vm._s(_vm.removePayload.name))])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }